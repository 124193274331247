import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import {  StorageServiceModule} from 'ngx-webstorage-service';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { modalCreer_pilote } from './pages/icons/icons.component';
import { modalCreer_drone } from './pages/maps/maps.component';
import { modalCreer_dr } from './pages/user/user.component';
import { modalCreer_type } from './pages/table/table.component';
import { modalCreer_depart } from './pages/notifications/notifications.component';
import { modalDetails_Mission, modalMaj_mission, modalMission_import } from './pages/typography/typography.component';

import { NgxCsvParserModule } from 'ngx-csv-parser';
import { LoginComponent } from "./login/login.component";
import { RecapitulatifComponent } from './recapitulatif/recapitulatif.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
    modalCreer_pilote,
    modalCreer_drone,
    modalCreer_dr,
    modalCreer_type,
    modalCreer_depart,
    modalMission_import,
    modalMaj_mission,
    modalDetails_Mission,
    RecapitulatifComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    NgxSpinnerModule,
    NgxCsvParserModule,
    ReactiveFormsModule,
    StorageServiceModule,
    FormsModule,
    ToastrModule.forRoot(),
    FooterModule,
    HttpClientModule,
    FixedPluginModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
