import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { modalCreer_drone } from '../maps/maps.component';

@Component({
    selector: 'icons-cmp',
    moduleId: module.id,
    templateUrl: 'icons.component.html'
})

export class IconsComponent{
data_pilote : any=[];
base_url : any='https://africantechnolab.com'
  data_id_mission: any=[];
  data_mission: any=[];
constructor(  private router: Router,
    private http : HttpClient,
    private modalService : NgbModal,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService)
{

this.liste_pilote()
}
creer_pilote()
{
    const modalRef = this.modalService.open(modalCreer_pilote);
    modalRef.result.then(
        (result) => {
     
        },
        (reason) => {
           
           if(reason==undefined)
           {
            this.liste_pilote()
           }
        }
      );

}
liste_pilote()
{
  this.http.get(this.base_url+'/suivie_heures/pilote/liste_pilote.php').subscribe(data=>{
    let ty : any=[]
    ty=data;
    this.data_pilote=data
    this.data_id_mission=[]
    this.data_mission=[]
    for(let i=0;i<ty.length;i++)
    {

      this.http.get(this.base_url+'/suivie_heures/pilote/get_mission.php?id_pilote='+ty[i].id_pilote).subscribe(data=>{
        let tys;
        tys=data;
        console.log(data)
       

        if(tys.length==0)
        {
         
           
            this.data_id_mission.push(ty[i].id_pilote)
            this.data_mission.push(0);
        }
        else
        {
          this.data_id_mission.push(ty[i].id_pilote)
          this.data_mission.push(tys[0]);
        }


    })
    }
 
   

}) 
}
modifier_pilote(id)
{
  const modalRef = this.modalService.open(modalCreer_pilote);
  modalRef.componentInstance.id_pilote =id
  modalRef.result.then(
      (result) => {
      
      },
      (reason) => {
      
         if(reason==undefined)
         {
          this.liste_pilote()
         }
      }
    );
}
supprimer_pilote(id)
{
  if (confirm("Vous vraiment retirer ce Pilote ?" )) {

    this.http.get(this.base_url+'/suivie_heures/pilote/verif_pilote.php?id_pilote='+id).subscribe(data=>{

if(data['success']==true)
         {
           //Verifier sil a des notes

           if (confirm('Ce Pilote est lié à des missions, voulez vous vraiment le supprimer ?'))
            {

              let postData = new FormData();
              postData.append('id_pilote' , id);

              this.http.post(this.base_url+'/suivie_heures/pilote/supprimer_pilote.php', postData).subscribe(data=>{
          
          if(data['success']==true)
                   {
                    
                  this.toastr.success('Pilote retiré avec succès !')
                 this.liste_pilote()
                
                  }
                  else
                  {
                      
                      this.toastr.error('Erreur /Pilote déja retiré !')
                      this.spinner.hide()
                      
          
                  }
          })

           }
           else

           {
            this.toastr.error('Action annulée')
           }
          
      
       // this.liste_cat()
      
        }
        else
        {
            
            //supprimer
            let postData = new FormData();
            postData.append('id_part' , id);
          
           
            this.http.post(this.base_url+'/suivie_heures/pilote/supprimer_pilote.php', postData).subscribe(data=>{
        
        if(data['success']==true)
                 {
                  
                this.toastr.success('Pilote retiré avec succès !')
               this.liste_pilote()
              
                }
                else
                {
                    
                    this.toastr.error('Erreur /Pilote déja retiré !')
                    this.spinner.hide()
                    
        
                }
        })
            

        }
})
  }
}
ouvrir_pilote(id)
{

}
}
@Component({
    template: `
      <div  class="modal-header" >
      <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Chargement...</p>
    </ngx-spinner>
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i>  Pilote  </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     <div class="row">
       <div class="col-6">
       Nom 
       </div>
       <div class="col-6">
      <input [(ngModel)]="nom_pilote" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
       </div>
      
     </div>
     <div class="row">
     <div class="col-6">
     Prenom 
     </div>
     <div class="col-6">
    <input type="text" [(ngModel)]="prenom_pilote" [ngModelOptions]="{standalone: true}" class="form-control" />
     </div>
    
   </div>
  
 
 
     
        
        
     
    
    
    
      
      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="ajout_pilote()">Valider</button>
      </div>
    `
  })
  export class modalCreer_pilote {
    @Input() public id_pilote;
    test_data: any;
   
    nom_pilote:any='';
    prenom_pilote:any='';
   
    data_pilote : any=[];
    base_url : any='https://africantechnolab.com';
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
    private spinner: NgxSpinnerService,
    private modalService:  NgbModal,
     public activeModal: NgbActiveModal,
     private toastr: ToastrService, private http : HttpClient) {
        //this.id_ut=this.storage.get('id_ut_form');
        
  
   


    }
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
       
        if(this.id_pilote!=undefined)
        {
this.get_info(this.id_pilote)
        }
    }

    get_info(id_pilote)
    {
        this.http.get(this.base_url+'/suivie_heures/pilote/get_pilote.php?id_pilote='+id_pilote).subscribe(data=>{
  
            this.data_pilote=data;
            this.nom_pilote=this.data_pilote[0].nom_pilote;
            this.prenom_pilote=this.data_pilote[0].prenom_pilote
        
            
           
           
           
  
        })
    }
  
  
    ajout_pilote()
    {
    
       
      if( this.nom_pilote=='' || this.prenom_pilote=='')
      {
          this.toastr.error('Champ(s) vide(s)')
      }
      else
  
      {
        
        this.spinner.show()
        let postData = new FormData();
        //alert(this.libelle)
        if(this.id_pilote==undefined)
        {
            postData.append('id_pilote' , 'rien');
            postData.append('nom_pilote' , this.nom_pilote);
            postData.append('prenom_pilote' , this.prenom_pilote);
           
           
            this.http.post(this.base_url+'/suivie_heures/pilote/ajouter_pilote.php', postData).subscribe(data=>{
      
            
             if(data['success']==true)
             {
                
                this.spinner.hide()
            this.toastr.success('Pilote Ajouté avec succès !')
           // this.liste_cat()
            this.modalService.dismissAll()
            }
            else
            {
              alert(data['success'])
                
                this.toastr.error('Erreur ! Un Autre Pilote porte les memes noms!')
                this.spinner.hide()
                
      
            }
        
        
        
        })
        }
        else
        {
            postData.append('id_pilote' , this.id_pilote);
            postData.append('nom_pilote' , this.nom_pilote);
            postData.append('prenom_pilote' , this.prenom_pilote);
         
           
            this.http.post(this.base_url+'/suivie_heures/pilote/ajouter_pilote.php', postData).subscribe(data=>{
      
         
             if(data['success']==true)
             {
                
                this.spinner.hide()
            this.toastr.success('Pilote Modifié avec succès !')
           // this.liste_cat()
            this.modalService.dismissAll()
            }
            else
            {
                
                this.toastr.error('Erreur ! Un Autre Pilote porte les memes noms!')
                this.spinner.hide()
                
      
            }
        
        
        
        })
        }
     
      }
           
           
  
        
    }
  }
