import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

declare interface TableData {
    headerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'table-cmp',
    moduleId: module.id,
    templateUrl: 'table.component.html'
})

export class TableComponent implements OnInit{
    public tableData1: TableData;
    public tableData2: TableData;
    base_url : any='https://africantechnolab.com'
    data_type : any=[]
    constructor(private router: Router,
        private http : HttpClient,
        private modalService : NgbModal,
        @Inject(SESSION_STORAGE) private storage: StorageService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService)
    {
     this.liste_type()   
    }
    ngOnInit(){
      
    }
    creer_type()
    {
        const modalRef = this.modalService.open(modalCreer_type);
        modalRef.result.then(
            (result) => {
         
            },
            (reason) => {
               
               if(reason==undefined)
               {
                this.liste_type()
               }
            }
          );
    }

    liste_type()
{
  this.http.get(this.base_url+'/suivie_heures/type_mission/liste_type.php').subscribe(data=>{
  
    this.data_type=data
   
 
   

}) 
}
modifier_type(id)
  {
    const modalRef = this.modalService.open(modalCreer_type);
  modalRef.componentInstance.id_type_mission =id
  modalRef.result.then(
      (result) => {
      
      },
      (reason) => {
      
         if(reason==undefined)
         {
          this.liste_type()
         }
      }
    );
  }
  supprimer_type(id)
  {
    if (confirm("Vous vraiment retirer ce Type ?" )) {
        
      
       
      this.http.get(this.base_url+'/suivie_heures/type_mission/verif_type.php?id_type_mission='+id).subscribe(data=>{
  
  if(data['success']==true)
           {
             //Verifier sil a des notes

             if (confirm("Ce Type de mission est lié à des missions, voulez vous vraiment le supprimer ?"))
              {

                let postData = new FormData();
                postData.append('id_type_mission' , id);
              
               
                this.http.post(this.base_url+'/suivie_heures/type_mission/supprimer_type.php', postData).subscribe(data=>{
            
            if(data['success']==true)
                     {
                      
                    this.toastr.success('Type retiré avec succès !')
                   this.liste_type()
                  
                    }
                    else
                    {
                        
                        this.toastr.error('Erreur /Type déja retiré !')
                        this.spinner.hide()
                        
            
                    }
            })

             }
             else

             {
              this.toastr.error('Action annulée')
             }
            
        
         // this.liste_cat()
        
          }
          else
          {
              
              //supprimer
              let postData = new FormData();
              postData.append('id_part' , id);
            
             
              this.http.post(this.base_url+'/suivie_heures/type_mission/supprimer_type.php', postData).subscribe(data=>{
          
          if(data['success']==true)
                   {
                    
                  this.toastr.success('Type retiré avec succès !')
                 this.liste_type()
                
                  }
                  else
                  {
                      
                      this.toastr.error('Erreur /Type déja retiré !')
                      this.spinner.hide()
                      
          
                  }
          })
              
  
          }
  })
    }
  }
}
@Component({
    template: `
    <div  class="modal-header" >
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    >
    <p style="font-size: 20px; color: white">Chargement...</p>
    </ngx-spinner>
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i>  Tye Mission  </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
    <div class="row">
     <div class="col-6">
     Libelle 
     </div>
     <div class="col-6">
    <input [(ngModel)]="libelle_type_mission" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
     </div>
    
    </div>
    
    
    
    
    
      
      
    
    
    
    
    
    
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajout_type()">Valider</button>
    </div>
    `
    })
    export class modalCreer_type {
    @Input() public id_type_mission;
    test_data: any;
    
    libelle_type_mission:any='';
    
    data_type_mission : any=[];
    base_url : any='https://africantechnolab.com';
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
    private spinner: NgxSpinnerService,
    private modalService:  NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService, private http : HttpClient) {
      //this.id_ut=this.storage.get('id_ut_form');
      
    
    
    
    
    }
    ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
     
      if(this.id_type_mission!=undefined)
      {
    this.get_info(this.id_type_mission)
      }
    }
    
    get_info(id_type_mission)
    {
      this.http.get(this.base_url+'/suivie_heures/type_mission/get_type_mission.php?id_type_mission='+id_type_mission).subscribe(data=>{
    
          this.data_type_mission=data;
          this.libelle_type_mission=this.data_type_mission[0].libelle_type_mission;
        
          
         
         
         
    
      })
    }
    
    
    ajout_type()
    {
    
     
    if( this.libelle_type_mission=='' )
    {
        this.toastr.error('Champ(s) vide(s)')
    }
    else
    
    {
      this.spinner.show()
      let postData = new FormData();
      //alert(this.libelle)
      if(this.id_type_mission==undefined)
      {
          postData.append('id_type_mission' , 'rien');
          postData.append('libelle_type_mission' , this.libelle_type_mission);
          
         
          this.http.post(this.base_url+'/suivie_heures/type_mission/ajouter_type.php', postData).subscribe(data=>{
    
          
           if(data['success']==true)
           {
              
              this.spinner.hide()
          this.toastr.success('Type Mission Ajouté avec succès !')
         // this.liste_cat()
          this.modalService.dismissAll()
          }
          else
          {
              
              this.toastr.error('Erreur ! Un Autre Type porte les memes noms!')
              this.spinner.hide()
              
    
          }
      
      
      
      })
      }
      else
      {
          postData.append('id_type_mission' , this.id_type_mission);
          postData.append('libelle_type_mission' , this.libelle_type_mission);
         
       
         
          this.http.post(this.base_url+'/suivie_heures/type_mission/ajouter_type.php', postData).subscribe(data=>{
    
       
           if(data['success']==true)
           {
              
              this.spinner.hide()
          this.toastr.success('Type Modifié avec succès !')
         // this.liste_cat()
          this.modalService.dismissAll()
          }
          else
          {
              
              this.toastr.error('Erreur ! Un Autre Type porte les memes noms!')
              this.spinner.hide()
              
    
          }
      
      
      
      })
      }
    
    }
         
         
    
      
    }
    }
    