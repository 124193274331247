import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/Tbd',     title: 'Tableau de Bord',         icon:'',       class: '' },
    { path: '/Pilotes',         title: 'Pilotes',             icon:'',    class: '' },
    { path: '/Drones',          title: 'Drones',              icon:'',      class: '' },
    { path: '/Tel',          title: 'Telephones',              icon:'',      class: '' },
    { path: '/Departs', title: 'Departs',     icon:'',    class: '' },
    { path: '/Dr',          title: 'DR',      icon:'',  class: '' },
    { path: '/Type',         title: 'Type Mission',        icon:'',    class: '' },
    { path: '/Mission',    title: 'Mission',        icon:'', class: '' },
    { path: '/Recap',    title: 'Recapitulatif',        icon:'', class: '' },
    { path: '/Maint',    title: 'Maintenance',        icon:'', class: '' }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
