import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-recapitulatif',
  templateUrl: './recapitulatif.component.html',
  styleUrls: ['./recapitulatif.component.css']
})
export class RecapitulatifComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;  
  @ViewChild('TABLE1', { static: false }) TABLE1: ElementRef;  
  @ViewChild('TABLE2', { static: false }) TABLE2: ElementRef;  
  @ViewChild('TABLE3', { static: false }) TABLE3: ElementRef;  
  mois_mission : any=0;
  annee_mission : any=0;
  
  base_url : any='https://africantechnolab.com';
  data_rech_drones : any=[];
  data_rech_pilotes : any=[];
  data_rech_type:any=[];
  duree_drone : any=0;
  duree_pilote : any=0;
  id_type_mission : any=0;
  duree_type: number;
  data_type: any=[];
  data_pil_drone : any=[];
  duree_pil_drone: number;
  constructor(  private http : HttpClient,private toastr: ToastrService) { 
this.liste_type_mission();
this.afficher();

  }
  ExportTOExcelMission()
  {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE2.nativeElement);  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'MISSION');  
    XLSX.writeFile(wb, 'Mission_'+this.annee_mission+'_'+this.mois_mission+'.xlsx'); 
  }
  ExportTOExcelDronPi()
  {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE3.nativeElement);  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'DRONEPILOTE');  
    XLSX.writeFile(wb, 'Drone_Pilote'+this.annee_mission+'_'+this.mois_mission+'.xlsx'); 
  }
  ExportTOExcelPilote()
  {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE1.nativeElement);  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'PILOTES');  
    XLSX.writeFile(wb, 'pilotes_'+this.annee_mission+'_'+this.mois_mission+'.xlsx');  
  }
  ExportTOExcelDrone() {  
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'DRONES');  
    XLSX.writeFile(wb, 'drones_'+this.annee_mission+'_'+this.mois_mission+'.xlsx');  
  } 


 
  ngOnInit(): void {
  }
  show_h(valeur)
  { 
    return Math.floor(valeur / 60);
           
    
  }
  show_m(valeur)
  {
    return  valeur % 60;

  }
  liste_type_mission()
  {
    this.http.get(this.base_url+'/suivie_heures/type_mission/liste_type.php').subscribe(data=>{
  
      this.data_type=data
     
   
     
  
  }) 

  }

  afficher()
  {
if(this.mois_mission==0 && this.annee_mission==0)
{

//alert("veuillez selectionner une option svp");
this.toastr.error('veuillez selectionner une option svp !')
}
else
{
  
  this.http.get(this.base_url+'/suivie_heures/recapitulatif/rech_recap.php?annee_mission='+this.annee_mission+'&mois_mission='+this.mois_mission+'&id_type_mission='+this.id_type_mission).subscribe(data=>{
  console.log(data);

this.data_rech_drones=data;
this.duree_drone=0;
for(let i=0;i<this.data_rech_drones.length;i++)
{
  this.duree_drone=this.duree_drone + parseInt(this.data_rech_drones[i].DUREE);
 
}

 
  })

  this.http.get(this.base_url+'/suivie_heures/recapitulatif/rech_recap_pilotes.php?annee_mission='+this.annee_mission+'&mois_mission='+this.mois_mission+'&id_type_mission='+this.id_type_mission).subscribe(data=>{
    console.log(data);
  
  this.data_rech_pilotes=data;
  this.duree_pilote=0;
  for(let i=0;i<this.data_rech_pilotes.length;i++)
{
  this.duree_pilote=this.duree_pilote + parseInt(this.data_rech_pilotes[i].DUREE);
 
}
  
   
    })


    this.http.get(this.base_url+'/suivie_heures/recapitulatif/rech_recap_type_mission.php?annee_mission='+this.annee_mission+'&mois_mission='+this.mois_mission).subscribe(data=>{
      console.log(data);
    
    this.data_rech_type=data;
    this.duree_type=0;
    for(let i=0;i<this.data_rech_type.length;i++)
  {
    this.duree_type=this.duree_type + parseInt(this.data_rech_type[i].DUREE);
   
  }
    
     
      })

      this.http.get(this.base_url+'/suivie_heures/recapitulatif/rech_recap_pilotes_typemission.php?annee_mission='+this.annee_mission+'&mois_mission='+this.mois_mission+'&id_type_mission='+this.id_type_mission).subscribe(data=>{
        console.log(data);
      
      this.data_pil_drone=data;
      this.duree_pil_drone=0;
      for(let i=0;i<this.data_pil_drone.length;i++)
    {
      this.duree_pil_drone=this.duree_pil_drone + parseInt(this.data_pil_drone[i].DUREE);
     
    }
      
       
        })


    this.toastr.success('Terminé')

}

  }
}
