import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE,StorageService } from 'ngx-webstorage-service/';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService,private router : Router)
  {
    if(this.storage.get('id_ut_form')==undefined)
    {
      this.router.navigate(['/login'])
    }
    else
    {
      this.router.navigate(['/Tbd'])
    }
  }
  

}
