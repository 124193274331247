import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SESSION_STORAGE,StorageService } from 'ngx-webstorage-service/';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
mail_ut : any='';
mdp_ut : any='';

base_url : any='https://africantechnolab.com'
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
  private router : Router,private spinner: NgxSpinnerService,private http: HttpClient,
  private toastr: ToastrService
  ) { 

    if(this.storage.get('id_ut_form')==undefined)
    {
      this.router.navigate(['login'])
    }
    else
    {
      this.router.navigate(['dashboard'])
    }
  }

  ngOnInit(): void {
  }
  connexion()
  {
    if(this.mail_ut =='' || this.mdp_ut=='')
    {
      this.toastr.error('Champ(s) Vide(s) !');
    }
    else
    {
      this.spinner.show()

      let postData = new FormData();
      postData.append('mdp_ut' , this.mdp_ut);
      postData.append('email_ut' , this.mail_ut);

     
      this.http.post(this.base_url+'/suivie_heures/connexion/connexion.php',
      
      postData).subscribe(data=>{
  
      
       
        if(data['success']==true)
        {
          this.toastr.success('Connecté avec succès !');
          this.storage.set('id_ut_form', data['id_ut']);
          this.storage.set('nom_ut_form', data['nom_ut']); 
          this.storage.set('prenom_ut_form', data['prenom_ut']); 
          this.storage.set('email_ut_form', data['email_ut']); 
          location.reload()
          this.spinner.hide();
        }
        else
        {
          this.toastr.error('Erreur de connexion !');
          this.spinner.hide();
        }
        
      
      },
      error  => {
      
      
      
      })
         
    }
   
  }

}
