import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { Alert } from 'bootstrap';
@Component({
    selector: 'typography-cmp',
    moduleId: module.id,
    templateUrl: 'typography.component.html'
})

export class TypographyComponent{
    data_mission : any=[];
    base_url : any='https://africantechnolab.com'
    data_drone: any=[];
    data_id_type: any=[];
    data_type:any=[];
    data_id_drone: any=[];
    data_id_pilote: any=[];
    data_pilote: any=[];
    data_id_prevol: any=[];
    data_prevol: any=[];
    data_id_postvol: any=[];
    data_postvol: any=[];
    data_depart: any=[];
    data_id_depart : any=[];
    data_id_dr: any=[];
    data_dr: any=[];
    data_st_pilote: Object;
    data_st_drone: Object;
    data_st_type: Object;
    data_st_dr: Object;
    data_st_depart: Object;
    id_depart: string='';
    id_dr: string='';
    id_type_mission: string='';
    id_drone: string='';
    id_pilote: string='';
    id_mois: any;
  duree_mission: any=0;
constructor(private router: Router,
    private ngxCsvParser: NgxCsvParser,
    private http : HttpClient,
    private modalService : NgbModal,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService)
{
   
this.liste_mission();

this.liste_depart();



}
maj()
{
  this.http.get(this.base_url+'/suivie_heures/add_data_export.php').subscribe(data=>{
    if (data['success'] == true)
    {
      this.toastr.success('('+ data['nbre'] +') Missions Importé(s)')
    }

 
  })
 
}
recherche()
{
  //2020-01
   // alert(this.id_mois)
    this.data_mission=[]
    this.http.get(this.base_url+'/suivie_heures/mission/search.php?id_depart='+this.id_depart+'&id_dr='+this.id_dr+'&id_type_mission='+this.id_type_mission+'&id_drone='+this.id_drone+'&id_pilote='+this.id_pilote).subscribe(data=>{
       
        this.data_mission=data;
        this.duree_mission=0;
        for(let i=0;i<this.data_mission.length;i++)
        {
          this.duree_mission=Number(this.duree_mission) + Number(this.data_mission[i].duree_mission)
        }
        if(this.data_mission.length==0)
        {
            this.toastr.error('0 Resultat');
        }
        else
        {
            this.toastr.success(this.data_mission.length +' Resultats')
        }
      

    })

}
ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.liste_drone();
    this.liste_dr();
    this.liste_pilote()
    this.liste_type();
    this.maj();
}

liste_pilote()
{
    this.http.get(this.base_url+'/suivie_heures/pilote/liste_pilote.php').subscribe(data=>{
  
        let ty : any=[];
        ty=data;
        this.data_st_pilote=data;
     
        for(let i=0;i<ty.length;i++)
        {
            

            this.data_id_pilote.push(ty[i].id_pilote)
                  this.data_pilote.push(ty[i].nom_pilote +' '+ ty[i].prenom_pilote);
           

        }
       
      
       
    
    })  
}
liste_type()
{
    this.http.get(this.base_url+'/suivie_heures/type_mission/liste_type.php').subscribe(data=>{
  
        let ty : any=[];
        ty=data;
        
        this.data_st_type=data;
        for(let i=0;i<ty.length;i++)
        {
            

            this.data_id_type.push(ty[i].id_type_mission)
                  this.data_type.push(ty[i].libelle_type_mission);
           

        }
       
      
       
    
    })  
}
liste_drone()
{
    this.http.get(this.base_url+'/suivie_heures/drone/liste_drone.php').subscribe(data=>{
  
        let ty : any=[];
        ty=data;
        this.data_st_drone=data;
        for(let i=0;i<ty.length;i++)
        {
            

            this.data_id_drone.push(ty[i].id_drone)
            this.data_drone.push(ty[i].libelle_drone);
           

        }
       
      
       
    
    })  
}
liste_dr()
{
    this.http.get(this.base_url+'/suivie_heures/dr/liste_dr.php').subscribe(data=>{
  
        let ty : any=[];
        ty=data;
        this.data_st_dr=data;
        for(let i=0;i<ty.length;i++)
        {

            this.data_id_dr.push(ty[i].id_dr)
            this.data_dr.push(ty[i].libelle_dr);

        }
       
     
       
    
    })  
}

liste_depart()
{
    this.http.get(this.base_url+'/suivie_heures/departs/liste_depart.php').subscribe(data=>{
  
        let ty : any=[];
        ty=data;
        this.data_st_depart=data;
        for(let i=0;i<ty.length;i++)
        {

            this.data_id_depart.push(ty[i].id_depart)
            this.data_depart.push(ty[i].libelle_depart);

        }
       
     
       
    
    })  
}
liste_mission()
{
    this.id_depart='';
    this.id_dr='';
    this.id_type_mission='';
    this.id_drone='';
    this.id_pilote='';
    this.http.get(this.base_url+'/suivie_heures/mission/liste_mission.php').subscribe(data=>{
  
        
        let test : any=[]
     
        test=data;
     
        this.data_mission=data;
        for(let i=0;i<this.data_mission.length;i++)
        {
          this.duree_mission=Number(this.duree_mission) + Number(this.data_mission[i].duree_mission)
        }

        
       
     
       
    
    }) 
}

convertMinsToHrsMins(mins) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  let heures ;
  let minutes ;
  heures = h < 10 ? '0' + h : h;
  minutes = m < 10 ? '0' + m : m;
  return `${heures} H ${minutes} Min`;
}
importer()
{
    const modalRef = this.modalService.open(modalMission_import);
    modalRef.result.then(
        (result) => {
     
        },
        (reason) => {
           
           if(reason==undefined)
           {
            this.liste_mission()
           }
        }
      );
}
ouvrir_mission(id)
{
    
    this.storage.set('id_mission', id);
    this.router.navigate(['Details'])
    .then(() => {
  //    window.location.reload();
    }); 
}
supprimer_mission(id)
{
    
}

}
@Component({
  template: `
  <div  class="modal-header" >
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Chargement...</p>
  </ngx-spinner>
  <h4 class="modal-title " style="text-align:center !important">
  <i class="la la-eye"></i>  MISSIONS EN ATTENTE  </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" >
  <div class="row">
   <div class="col-6">
  ({{nbre}}) fichiers à importer
   </div>
   <div class="col-3">
  
   <button type="button" class="btn btn-primary" (click)="importer()">
  Importer
 </button>
   </div>
  
  </div>
  
  
  
  
  
    
    
  
  
  
  
  
  
  </div>
  <div class="modal-footer">
   
   
  </div>
  `
  })
  export class modalMaj_mission {
  @Input() public id_type_mission;
  test_data: any;
  files:any=  [];
  libelle_type_mission:any='';
  csvRecords: any[] = [];
header = false;
  data_type_mission : any=[];
  temoin=0
   base_url : any='https://africantechnolab.com'
  nbre: any=0;
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
  private ngxCsvParser: NgxCsvParser,
  private spinner: NgxSpinnerService,
  private modalService:  NgbModal,
  public activeModal: NgbActiveModal,
  private toastr: ToastrService, private http : HttpClient) {
    //this.id_ut=this.storage.get('id_ut_form');
    
  
  
  this.check_file()
  
  }
  importer()
  {
    
  }
check_file()
{
  this.http.get('http://africantechnolab.com/suivie_heures/liste_dir.php').subscribe(data=>{
  
        
    let test : any=[]
 
    test=data;
    this.nbre=data['nbre'];
    console.log(data['nbre'])
 

    
   
 
   

}) 
}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
   

  }
  
  
 
  }
@Component({
    template: `
    <div  class="modal-header" >
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    >
    <p style="font-size: 20px; color: white">Chargement...</p>
    </ngx-spinner>
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i>  IMPORT MISSION  </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
    <div class="row">
     <div class="col-6">
        Fichier CSV 
     </div>
     <div class="col-6">
    <input (change)="onFileChange($event)" type="file" accept=".csv"  class="form-control" />
     </div>
    
    </div>
    
    
    
    
    
      
      
    
    
    
    
    
    
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" *ngIf="temoin==0"  (click)="verifier()">Verifier</button>
      <button type="button" class="btn btn-outline-dark" *ngIf="temoin==1"  (click)="import()">Importer</button>
      <button type="button" class="btn btn-danger" *ngIf="temoin==2" >Erreur Format, ajoutez un autre CSV</button>
      
    </div>
    `
    })
    export class modalMission_import {
    @Input() public id_type_mission;
    test_data: any;
    files:any=  [];
    libelle_type_mission:any='';
    csvRecords: any[] = [];
  header = false;
    data_type_mission : any=[];
    temoin=0
    base_url : any='http://africantechnolab.com/'
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
    private ngxCsvParser: NgxCsvParser,
    private spinner: NgxSpinnerService,
    private modalService:  NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService, private http : HttpClient) {
      //this.id_ut=this.storage.get('id_ut_form');
      
    
    
    
    
    }
    onFileChange(event)  {
       this.temoin=0;
       this.files=[];
        this.files.push(event.target.files[0]);
       
    
  }
    ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
     

    }
    
    
    import()
    {
    
     if(this.files.length==0)
     {
        this.toastr.error('Fichier Non ajouté !')
     }
     else
     {
        //Verifier la form du CSV

        this.ngxCsvParser.parse(this.files[0], { header: this.header, delimiter: ',' })
        .pipe().subscribe((result: Array<any>) => {
   
          console.log('Result', result.length);
          let entête: any='ID DR;ID DEPART;ID TYPE_MISSION;LONG;LAT;ID PILOTE;ID_DRONE;HEURE DEBUT;HEURE FIN;DUREE;MOIS;ANNEE;JOUR';
          console.log(result[0][0])
          console.log(entête)
          if (entête==result[0][0])
            {
                this.temoin=1
                let postData = new FormData();
                postData.append('file[]' , this.files[0]);
                this.http.post(this.base_url+'/suivie_heures/test_csv/csv.php', postData).subscribe(data=>{
                    console.log(data)
            
                    if(data['success']==true)
                             {

                                this.toastr.success('CSV importer avec succès !')


                             }
                             else
                             {
                                this.toastr.error('Erreur !')
                             }




                 })

            }
            else
            {
                this.toastr.error('Erreur !')
                this.temoin=2
            }
          this.csvRecords = result;
        }, (error: NgxCSVParserError) => {
          console.log('Error', error);
        });

       /* let postData = new FormData();
        postData.append('file[]' , this.files[0]);*/
     }
    
    }
    
    verifier()
    {
    
     if(this.files.length==0)
     {
        this.toastr.error('Fichier Non ajouté !')
     }
     else
     {
        //Verifier la form du CSV

        this.ngxCsvParser.parse(this.files[0], { header: this.header, delimiter: ',' })
        .pipe().subscribe((result: Array<any>) => {
   
          console.log('Result', result.length);
          let entête: any="ID DR;ID DEPART;ID TYPE_MISSION;LONG;LAT;ID PILOTE;ID_DRONE;HEURE DEBUT;HEURE FIN;DUREE;MOIS;ANNEE;JOUR";
          console.log(result[0][0])
          console.log(entête)
          if (entête==result[0][0])
            {
                this.temoin=1
            }
            else
            {
                this.temoin=2
            }
          this.csvRecords = result;
        }, (error: NgxCSVParserError) => {
          console.log('Error', error);
        });

       /* let postData = new FormData();
        postData.append('file[]' , this.files[0]);*/
     }
    
    }
    }

    @Component({
        template: `
        <div  class="modal-header" >
        <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="ball-scale-multiple"
        >
        <p style="font-size: 20px; color: white">Chargement...</p>
        </ngx-spinner>
        <h4 class="modal-title " style="text-align:center !important">
        <i class="la la-eye"></i>  DETAILS MISSION </h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body"  >
        <div class="row">
         
        <table class="table">
        <thead class=" text-primary">
          <th >
          VERIF HELICES
          </th>
          <th >
          CONTROL BATTERIE
          </th>
          <th >
          GPS
          </th>
          <th >
          CONTROL CARTE
          </th>
          <th >
          ALERTE COMPAS
          </th>
          <th >
          RETOUR VIDEO
          </th>
          <th >
          CONTROL V
          </th>
          <th >
          STERBLUE PLAN
          </th>
          <th >
          PLAN VOL
          </th>
          <th >
          BALISSAGE 
          </th>
        </thead>
        <tbody >
        <td >
        
        </td>
        <td >
        
        </td>
        <td >
        
        </td>
        <td >
       
        </td>
        <td >
      
        </td>
        <td >
       
        </td>
        <td >
        
        </td>
        <td >
        
        </td>
        <td >
       
        </td>
        <td >
       
        </td>

        </tbody>
        </table>


        </div>
        
        
        
        
        
          
          
        
        
        
        
        
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
          <button type="button" class="btn btn-outline-dark" *ngIf="temoin==0"  (click)="verifier()">Verifier</button>
          <button type="button" class="btn btn-outline-dark" *ngIf="temoin==1"  (click)="import()">Importer</button>
          <button type="button" class="btn btn-danger" *ngIf="temoin==2" >Erreur Format, ajoutez un autre CSV</button>
          
        </div>
        `
        })
        export class modalDetails_Mission {
        @Input() public id_type_mission;
        test_data: any;
        files:any=  [];
        libelle_type_mission:any='';
        csvRecords: any[] = [];
      header = false;
        data_type_mission : any=[];
        temoin=0
         base_url : any='http://africantechnolab.com/'
        constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
        private ngxCsvParser: NgxCsvParser,
        private spinner: NgxSpinnerService,
        private modalService:  NgbModal,
        public activeModal: NgbActiveModal,
        private toastr: ToastrService, private http : HttpClient) {
          //this.id_ut=this.storage.get('id_ut_form');
          
        
        
        
        
        }
        onFileChange(event)  {
           this.temoin=0;
           this.files=[];
            this.files.push(event.target.files[0]);
           
        
      }
        ngOnInit(): void {
          //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
          //Add 'implements OnInit' to the class.
         
    
        }
        
        
     
        
        verifier()
        {
        
         if(this.files.length==0)
         {
            this.toastr.error('Fichier Non ajouté !')
         }
         else
         {
            //Verifier la form du CSV
    
            this.ngxCsvParser.parse(this.files[0], { header: this.header, delimiter: ',' })
            .pipe().subscribe((result: Array<any>) => {
       
              console.log('Result', result.length);
              let entête: any="ID DR;ID DEPART;ID TYPE_MISSION;LONG;LAT;ID PILOTE;ID_DRONE;HEURE DEBUT;HEURE FIN;DUREE;MOIS;ANNEE;JOUR";
              console.log(result[0][0])
              console.log(entête)
              if (entête==result[0][0])
                {
                    this.temoin=1
                }
                else
                {
                    this.temoin=2
                }
              this.csvRecords = result;
            }, (error: NgxCSVParserError) => {
              console.log('Error', error);
            });
    
           /* let postData = new FormData();
            postData.append('file[]' , this.files[0]);*/
         }
        
        }
        }