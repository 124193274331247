import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
    selector: 'user-cmp',
    moduleId: module.id,
    templateUrl: 'user.component.html'
})

export class UserComponent implements OnInit{
    data_dr: any=[];
    base_url : any='https://africantechnolab.com'
    constructor(private router: Router,
      private http : HttpClient,
      private modalService : NgbModal,
      @Inject(SESSION_STORAGE) private storage: StorageService,
      private toastr: ToastrService,
      private spinner: NgxSpinnerService)
    {
this.liste_dr()
    }
    ngOnInit(){
    }
    creer_dr()
    {
      const modalRef = this.modalService.open(modalCreer_dr);
      modalRef.result.then(
          (result) => {
       
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_dr()
             }
          }
        );
    }
    modifier_dr(id)
  {
    const modalRef = this.modalService.open(modalCreer_dr);
  modalRef.componentInstance.id_dr =id
  modalRef.result.then(
      (result) => {
      
      },
      (reason) => {
      
         if(reason==undefined)
         {
          this.liste_dr()
         }
      }
    );
  }
  supprimer_dr(id)
  {
    if (confirm('Vous vraiment retirer ce Dr ?' )) {

      this.http.get(this.base_url+'/suivie_heures/dr/verif_dr.php?id_dr='+id).subscribe(data=>{
  
  if(data['success']==true)
           {
             //Verifier sil a des notes
  
             if (confirm('Ce Dr est lié à des missions, voulez vous vraiment le supprimer ?'))
              {
  
                let postData = new FormData();
                postData.append('id_dr' , id);
  
                this.http.post(this.base_url+'/suivie_heures/dr/supprimer_dr.php', postData).subscribe(data=>{
            
            if(data['success']==true)
                     {
                      
                    this.toastr.success('Dr retiré avec succès !')
                   this.liste_dr()
                  
                    }
                    else
                    {
                        
                        this.toastr.error('Erreur /Dr déja retiré !')
                        this.spinner.hide()
                        
            
                    }
            })
  
             }
             else
  
             {
              this.toastr.error('Action annulée')
             }
            
        
         // this.liste_cat()
        
          }
          else
          {
              
              //supprimer
              let postData = new FormData();
              postData.append('id_dr' , id);
            
             
              this.http.post(this.base_url+'/suivie_heures/dr/supprimer_dr.php', postData).subscribe(data=>{
          
          if(data['success']==true)
                   {
                    
                  this.toastr.success('DR retiré avec succès !')
                 this.liste_dr()
                
                  }
                  else
                  {
                      
                      this.toastr.error('Erreur /Dr déja retiré !')
                      this.spinner.hide()
                      
          
                  }
          })
              
  
          }
  })
    }
  }
  liste_dr()
  {
    this.http.get(this.base_url+'/suivie_heures/dr/liste_dr.php').subscribe(data=>{
  
      this.data_dr=data
     
   
     
  
  }) 
  }
}
@Component({
  template: `
  <div  class="modal-header" >
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Chargement...</p>
  </ngx-spinner>
  <h4 class="modal-title " style="text-align:center !important">
  <i class="la la-eye"></i>  Dr  </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" >
  <div class="row">
   <div class="col-6">
   Libelle 
   </div>
   <div class="col-6">
  <input [(ngModel)]="libelle_dr" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
   </div>
  
  </div>
  
  
  
  
  
    
    
  
  
  
  
  
  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
    <button type="button" class="btn btn-outline-dark" (click)="ajout_dr()">Valider</button>
  </div>
  `
  })
  export class modalCreer_dr {
  @Input() public id_dr;
  test_data: any;
  
  libelle_dr:any='';
  
  data_dr : any=[];
   base_url : any='https://africantechnolab.com'
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
  private spinner: NgxSpinnerService,
  private modalService:  NgbModal,
  public activeModal: NgbActiveModal,
  private toastr: ToastrService, private http : HttpClient) {
    //this.id_ut=this.storage.get('id_ut_form');
    
  
  
  
  
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
   
    if(this.id_dr!=undefined)
    {
  this.get_info(this.id_dr)
    }
  }
  
  get_info(id_dr)
  {
    this.http.get(this.base_url+'/suivie_heures/dr/get_dr.php?id_dr='+id_dr).subscribe(data=>{
  
        this.data_dr=data;
        this.libelle_dr=this.data_dr[0].libelle_dr;
      
        
       
       
       
  
    })
  }
  
  
  ajout_dr()
  {
  
   
  if( this.libelle_dr=='' )
  {
      this.toastr.error('Champ(s) vide(s)')
  }
  else
  
  {
    this.spinner.show()
    let postData = new FormData();
    //alert(this.libelle)
    if(this.id_dr==undefined)
    {
        postData.append('id_dr' , 'rien');
        postData.append('libelle_dr' , this.libelle_dr);
        
       
        this.http.post(this.base_url+'/suivie_heures/dr/ajouter_dr.php', postData).subscribe(data=>{
  
        
         if(data['success']==true)
         {
            
            this.spinner.hide()
        this.toastr.success('Dr Ajouté avec succès !')
       // this.liste_cat()
        this.modalService.dismissAll()
        }
        else
        {
            
            this.toastr.error('Erreur ! Un Autre Dr porte les memes noms!')
            this.spinner.hide()
            
  
        }
    
    
    
    })
    }
    else
    {
        postData.append('id_dr' , this.id_dr);
        postData.append('libelle_dr' , this.libelle_dr);
       
     
       
        this.http.post(this.base_url+'/suivie_heures/dr/ajouter_dr.php', postData).subscribe(data=>{
  
     
         if(data['success']==true)
         {
            
            this.spinner.hide()
        this.toastr.success('Dr Modifié avec succès !')
       // this.liste_cat()
        this.modalService.dismissAll()
        }
        else
        {
            
            this.toastr.error('Erreur ! Un Autre Dr porte les memes noms!')
            this.spinner.hide()
            
  
        }
    
    
    
    })
    }
  
  }
       
       
  
    
  }
  }