import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';


@Component({
    selector: 'notifications-cmp',
    moduleId: module.id,
    templateUrl: 'notifications.component.html'
})

export class NotificationsComponent{
  data_depart: any=[];
  base_url : any='https://africantechnolab.com'
  constructor(private router: Router,
    private http : HttpClient,
    private modalService : NgbModal,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {

this.liste_depart()

    }
 
  creer_depart()
  {
    const modalRef = this.modalService.open(modalCreer_depart);
    modalRef.result.then(
        (result) => {
     
        },
        (reason) => {
           
           if(reason==undefined)
           {
            this.liste_depart()
           }
        }
      );
  }
  modifier_depart(id)
{
  const modalRef = this.modalService.open(modalCreer_depart);
  modalRef.componentInstance.id_depart =id
  modalRef.result.then(
      (result) => {
      
      },
      (reason) => {
      
         if(reason==undefined)
         {
          this.liste_depart()
         }
      }
    );
}
supprimer_depart(id)
{
  if (confirm("Vous vraiment retirer ce Depart ?" )) {

    this.http.get(this.base_url+'/suivie_heures/departs/verif_depart.php?id_depart='+id).subscribe(data=>{

if(data['success']==true)
         {
           //Verifier sil a des notes

           if (confirm('Ce Depart est lié à des missions, voulez vous vraiment le supprimer ?'))
            {

              let postData = new FormData();
              postData.append('id_depart' , id);

              this.http.post(this.base_url+'/suivie_heures/departs/supprimer_depart.php', postData).subscribe(data=>{
          
          if(data['success']==true)
                   {
                    
                  this.toastr.success('Depart retiré avec succès !')
                 this.liste_depart()
                
                  }
                  else
                  {
                      
                      this.toastr.error('Erreur /Depart déja retiré !')
                      this.spinner.hide()
                      
          
                  }
          })

           }
           else

           {
            this.toastr.error('Action annulée')
           }
          
      
       // this.liste_cat()
      
        }
        else
        {
            
            //supprimer
            let postData = new FormData();
            postData.append('id_drone' , id);
          
           
            this.http.post(this.base_url+'/suivie_heures/departs/supprimer_depart.php', postData).subscribe(data=>{
        
        if(data['success']==true)
                 {
                  
                this.toastr.success('Depart retiré avec succès !')
               this.liste_depart()
              
                }
                else
                {
                    
                    this.toastr.error('Erreur /Depart déja retiré !')
                    this.spinner.hide()
                    
        
                }
        })
            

        }
})
  }
}
liste_depart()
{
  this.http.get(this.base_url+'/suivie_heures/departs/liste_depart.php').subscribe(data=>{
  
    this.data_depart=data
   
 
   

}) 
}

}
@Component({
template: `
<div  class="modal-header" >
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Chargement...</p>
</ngx-spinner>
<h4 class="modal-title " style="text-align:center !important">
<i class="la la-eye"></i>  Depart  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" >
<div class="row">
 <div class="col-6">
 Libelle 
 </div>
 <div class="col-6">
<input [(ngModel)]="libelle_depart" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
 </div>

</div>





  
  






</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
  <button type="button" class="btn btn-outline-dark" (click)="ajout_depart()">Valider</button>
</div>
`
})
export class modalCreer_depart {
@Input() public id_depart;
test_data: any;

libelle_depart:any='';

data_depart : any=[];
base_url : any='https://africantechnolab.com';
constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
private spinner: NgxSpinnerService,
private modalService:  NgbModal,
public activeModal: NgbActiveModal,
private toastr: ToastrService, private http : HttpClient) {
  //this.id_ut=this.storage.get('id_ut_form');
  




}
ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
 
  if(this.id_depart!=undefined)
  {
this.get_info(this.id_depart)
  }
}

get_info(id_depart)
{
  this.http.get(this.base_url+'/suivie_heures/departs/get_depart.php?id_depart='+id_depart).subscribe(data=>{

      this.data_depart=data;
      this.libelle_depart=this.data_depart[0].libelle_depart;
    
      
     
     
     

  })
}


ajout_depart()
{

 
if( this.libelle_depart=='' )
{
    this.toastr.error('Champ(s) vide(s)')
}
else

{
  this.spinner.show()
  let postData = new FormData();
  //alert(this.libelle)
  if(this.id_depart==undefined)
  {
      postData.append('id_depart' , 'rien');
      postData.append('libelle_depart' , this.libelle_depart);
      
     
      this.http.post(this.base_url+'/suivie_heures/departs/ajouter_depart.php', postData).subscribe(data=>{

      
       if(data['success']==true)
       {
          
          this.spinner.hide()
      this.toastr.success('Depart Ajouté avec succès !')
     // this.liste_cat()
      this.modalService.dismissAll()
      }
      else
      {
          
          this.toastr.error('Erreur ! Un Autre depart porte les memes noms!')
          this.spinner.hide()
          

      }
  
  
  
  })
  }
  else
  {
      postData.append('id_depart' , this.id_depart);
      postData.append('libelle_depart' , this.libelle_depart);
     
   
     
      this.http.post(this.base_url+'/suivie_heures/departs/ajouter_depart.php', postData).subscribe(data=>{

   
       if(data['success']==true)
       {
          
          this.spinner.hide()
      this.toastr.success('Depart Modifié avec succès !')
     // this.liste_cat()
      this.modalService.dismissAll()
      }
      else
      {
          
          this.toastr.error('Erreur ! Un Autre Depart porte les memes noms!')
          this.spinner.hide()
          

      }
  
  
  
  })
  }

}
     
     

  
}
}
